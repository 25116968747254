/* eslint-disable max-statements */
/* eslint-disable no-restricted-syntax */
import _, { camelCase, isEmpty } from 'lodash';
import { ReactElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InputOption, RuleTemplateInput, IRuleTemplate, RuleSettings, Condition, DataType, DynamicRuleTemplateInput } from './types';

import { JitAutoCompleteForm } from 'components/JitAutoCompleteForm/components/JitAutocompleteForm';
import { JitText } from 'components/JitText/JitText';

type GetterParams = {
  options?: InputOption[];
  defaultValue?: InputOption | InputOption[];
  multi?: boolean;
  inputValue?: DataType;
};

const findOption = ({ options, inputValue }: Pick<GetterParams, 'options' | 'inputValue'>) => inputValue && options?.find((option) => option.value === inputValue);

export const getSelectedOptionValues = ({ options, defaultValue, multi, inputValue }: GetterParams) => {
  const selectedValue = !multi
    ? findOption({ options,
      inputValue }) || inputValue
    : (inputValue as string[] || [])
      ?.map((value) => findOption({ options,
        inputValue: value }) || value)
      .filter(Boolean);

  const hasSelectedValue = !!((!multi && (selectedValue || selectedValue === 0))
    || (multi && Array.isArray(selectedValue) && selectedValue.length));

  return multi
    ? hasSelectedValue ? selectedValue : defaultValue || []
    : typeof selectedValue === 'number' || selectedValue
      ? selectedValue
      : defaultValue;
};

type GetRuleInputParams = {
  inputTemplate: RuleTemplateInput;
  inputValue: DataType;
  inputKey: string;
  autoFocus: boolean;
} & Pick<GetConditionSentenceParams, 'isEditMode' | 'onFieldChange' | 'index'>;

const getRuleInput = ({
  inputTemplate,
  inputValue,
  isEditMode,
  onFieldChange,
  index,
  inputKey,
  autoFocus,
}: GetRuleInputParams): ReactElement | null => {
  const sharedProps = {
    key: inputKey,
    type: JitAutoCompleteForm,
    props: {
      placeHolder: inputTemplate?.placeholder,
      fontSize: 14,
      fitContent: true,
      variant: 'light',
      inputValue,
      defaultValue: inputTemplate?.defaultValue,
      disabled: !isEditMode,
      maxDisplayedTags: 1,
      autoFocus,
      maxInputWidth: 150,
    },
  };

  switch (inputTemplate?.inputType) {
    case 'number':
      return {
        ...sharedProps,
        props: {
          ...sharedProps.props,
          isSingleValue: true,
          setSelectedValue: (newValue: number) => {
            onFieldChange(inputKey, newValue, index);
          },
          type: 'number',
          fitContent: true,
          maxDisplayedTags: 1,
          clearOnBlur: false,
          ...inputTemplate?.validation,
        },
      };
    case 'text':
      return {
        ...sharedProps,
        props: {
          ...sharedProps.props,
          placeHolder: inputTemplate.placeholder,
          isSingleValue: !inputTemplate.multi,
          fitContent: true,
          setSelectedValue: (newValue: string | string[]) => {
            onFieldChange(inputKey, newValue, index);
          },
          multi: inputTemplate?.multi,
          type: 'text',
          freeSolo: inputTemplate?.multi,
        },
      };

    case 'select':
      return {
        ...sharedProps,
        props: {
          ...sharedProps.props,
          options: inputTemplate?.options || [],
          getOptionLabel: (option: InputOption) => option?.label,
          getOptionKey: (option: InputOption) => option?.value,
          placeHolder: inputTemplate.placeholder,
          isSingleValue: !inputTemplate.multi,
          setSelectedValue: (newValue: InputOption | InputOption[]) => {
            const fieldValue = Array.isArray(newValue) ? newValue.map((v) => v.value) : newValue?.value;
            onFieldChange(inputKey, fieldValue, index);
          },
          disableCloseOnSelect: inputTemplate?.multi,
          optionsCallback: inputTemplate?.optionsCallback,
          multi: inputTemplate?.multi,
          showArrow: false,
          clearOnBlur: false,
        },
      };

    default:
      return null;
  }
};

export const getDynamicConfig = (
  inputTemplate: RuleTemplateInput,
  data: RuleSettings | Condition,
  inputKey: string,
): DynamicRuleTemplateInput | undefined => {
  const entityKey = _.camelCase(data.conditionEntity as string);
  const attributeKey = _.camelCase(data.conditionAttribute as string);

  if (attributeKey && (inputKey === 'conditionValue' || inputKey === 'conditionOperator')) {
    return inputTemplate.dynamicConfig?.[entityKey]?.[attributeKey as keyof RuleTemplateInput['dynamicConfig']];
  }

  if (inputKey === 'conditionAttribute' || inputKey === 'conditionValue' || inputKey === 'conditionOperator') {
    return inputTemplate.dynamicConfig?.[entityKey];
  }

  const dependsOnValue = data[_.camelCase(inputTemplate.dependsOn) as keyof RuleTemplateInput['dynamicConfig']];
  return inputTemplate.dynamicConfig?.[dependsOnValue];
};

type GetConditionSentenceParams = {
  conditionSentence: string;
  inputTemplates: IRuleTemplate['inputs'];
  data: RuleSettings | Condition;
  isEditMode: boolean;
  onFieldChange: (field: string, value: DataType, index?: number) => void;
  index?: number;
};

export const getConditionSentence = ({ conditionSentence, inputTemplates, data, isEditMode, onFieldChange, index }: GetConditionSentenceParams) => {
  const conditionFormats: ReactElement[] = [];
  const conditionSentenceSplit = conditionSentence
    .split(/(\{[^}]+\})/)
    .map((w) => w.trim())
    .filter(Boolean);

  let firstEmptyInputFocused = false;

  for (const word of conditionSentenceSplit) {
    if (word.startsWith('{')) {
      const inputKey = _.camelCase(word.slice(1, -1)) as keyof (RuleSettings | Condition);
      let inputTemplate = inputTemplates[inputKey];

      if (!isEmpty(inputTemplate) && inputTemplate.dependsOn && inputTemplate.dynamicConfig) {
        const selectedInput = getDynamicConfig(inputTemplate, data, inputKey);
        inputTemplate = { ...inputTemplate,
          ...selectedInput };
      }

      if (!(inputKey === 'conditionOperator' && (!inputTemplate?.options || inputTemplate?.options?.length === 1))) {
        const inputValue = data[inputKey] as DataType;
        const shouldAutoFocus = !firstEmptyInputFocused && (inputValue === '' || inputValue === undefined);

        if (shouldAutoFocus) {
          firstEmptyInputFocused = true;
        }

        const input = getRuleInput({
          inputTemplate: inputTemplate!,
          inputValue,
          isEditMode,
          onFieldChange,
          index,
          inputKey,
          autoFocus: shouldAutoFocus,
        });

        if (input) conditionFormats.push(input);
      }
    } else {
      conditionFormats.push({
        key: uuidv4(),
        type: JitText,
        props: {
          text: word,
          muted: true,
          noWrap: true,
        },
      });
    }
  }

  return conditionFormats;
};

export const shouldIncludeFieldInDependencyChain = (
  fieldName: string,
  settings: RuleSettings | Condition,
  inputs: IRuleTemplate['inputs'],
): boolean => {
  const inputTemplate = inputs[fieldName];
  if (!inputTemplate) return false;

  if (fieldName === 'conditionOperator') {
    const dynamicTemplate = getDynamicConfig(inputTemplate, settings, fieldName);
    return !!(dynamicTemplate?.options && dynamicTemplate?.options.length > 1);
  }

  return true;
};

export const getDependentFields = (
  field: string,
  inputs: IRuleTemplate['inputs'],
  settings: RuleSettings | Condition,
): string[] => {
  const directDependents = Object.keys(inputs)
    .filter((key) => {
      const isDependentField = camelCase(inputs[key]?.dependsOn) === field;
      return isDependentField && shouldIncludeFieldInDependencyChain(key, settings, inputs);
    });

  const nestedDependents = directDependents
    .flatMap((dependentField) => getDependentFields(dependentField, inputs, settings));

  return [...directDependents, ...nestedDependents];
};

export const getBaseConditionRootFields = (inputs: IRuleTemplate['inputs'], baseCondition: string): string[] => {
  // Extract field names from base condition sentence
  const baseConditionFields = (baseCondition.match(/\{([^}]+)\}/g) || [])
    .map((match) => camelCase(match.slice(1, -1)));

  // Find fields from base condition that are not dependent on other fields
  return baseConditionFields.filter((key) => {
    const input = inputs[key];
    return !input?.dependsOn;
  });
};
