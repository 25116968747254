import { useCallback, useEffect, useMemo, useState } from 'react';

import { ChooseGroup } from '../StepComponents/ChooseGitlabGroup/ChooseGroup';
import { IntegrationRole } from '../StepComponents/ChooseIntegrationMethod/ChooseIntegrationMethod';

import {
  IGitlabIntegrationWizardStep,
  IGitlabIntegrationWizardStepStatus,
} from 'components/GitlabIntegrationWizard/GitlabIntegrationWizard';
import { useTenantContext } from 'context/TenantContext';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import { GitlabAccessLevel } from 'types/interfaces';

interface Props {
  incrementStepIndex: () => void;
  selectedGroup?: IGroup;
  setSelectedGroup: (group: IGroup) => void;
  selectedRole: IntegrationRole;
  handleClose: () => void;
}

export const useChooseGroupStep = ({
  incrementStepIndex,
  selectedGroup,
  setSelectedGroup,
  selectedRole,
  handleClose,
}: Props): IGitlabIntegrationWizardStep => {
  const [isStepCompleted, setIsStepCompleted] = useState<boolean>(false);
  const { createInstallation, createCentralizedProject } = useGitlabService();
  const [isLoading, setIsLoading] = useState(false);
  const accessLevel = selectedRole === 'owner' ? GitlabAccessLevel.OWNER : GitlabAccessLevel.MAINTAINER;
  const [selectedSubgroup, setSelectedSubgroup] = useState<IGroup>();
  const { gitlabInstallation } = useTenantContext();

  useEffect(() => {
    if (gitlabInstallation && !selectedGroup) {
      setSelectedGroup({
        id: gitlabInstallation.installation_id,
        name: gitlabInstallation.owner,
        path: gitlabInstallation.owner,
        fullPath: gitlabInstallation.owner,
      });

      if (gitlabInstallation.centralized_repo_asset && !selectedSubgroup) {
        setSelectedSubgroup({
          id: gitlabInstallation.centralized_repo_asset.asset_id,
          name: gitlabInstallation.centralized_repo_asset.asset_name,
          path: gitlabInstallation.centralized_repo_asset.asset_name,
          fullPath: gitlabInstallation.centralized_repo_asset.asset_name,
        });

        setIsStepCompleted(true);
      }
    }
  }, [gitlabInstallation, selectedGroup, setSelectedGroup, selectedSubgroup, setSelectedSubgroup]);

  const status = useMemo(() => {
    if (isStepCompleted) return IGitlabIntegrationWizardStepStatus.COMPLETE;

    return IGitlabIntegrationWizardStepStatus.ACTIVE;
  }, [isStepCompleted]);

  const onContinueButtonClick = useCallback(async () => {
    if (isStepCompleted) {
      incrementStepIndex();
      return;
    }

    if (!selectedGroup) return;

    setIsLoading(true);
    const installationResponse = await createInstallation({
      group_id: selectedGroup.id,
      group_name: selectedGroup.name,
      group_slug: selectedGroup.path,
      access_level: accessLevel,
    });
    if (installationResponse?.status === 201 || installationResponse?.status === 409) {
      const centralizedProjectResponse = await createCentralizedProject(selectedSubgroup ? { group_id: selectedSubgroup.id } : undefined);
      if (centralizedProjectResponse?.status === 200) {
        setIsStepCompleted(true);
        incrementStepIndex();
      }
    }
    setIsLoading(false);
  }, [accessLevel, selectedGroup, createInstallation, incrementStepIndex, selectedSubgroup, createCentralizedProject, isStepCompleted]);

  const chooseGroupStep = useMemo(
    () => ({
      title: 'GitlabIntegrationWizard.chooseGitlabGroup.tabText',
      component: () => (
        <ChooseGroup
          handleClose={handleClose}
          isLoading={isLoading}
          onContinueButtonClick={onContinueButtonClick}
          selectedGroup={selectedGroup}
          selectedRole={selectedRole}
          selectedSubgroup={selectedSubgroup}
          setSelectedGroup={setSelectedGroup}
          setSelectedSubgroup={setSelectedSubgroup}
        />
      ),
      status,
    }),
    [
      selectedRole,
      status,
      isLoading,
      onContinueButtonClick,
      selectedGroup,
      setSelectedGroup,
      selectedSubgroup,
      setSelectedSubgroup,
      handleClose,
    ],
  );

  return chooseGroupStep;
};
