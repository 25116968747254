import { FC, useCallback, useState } from 'react';

import { IntegrationRole } from '../ChooseIntegrationMethod/ChooseIntegrationMethod';

import styles from './ChooseGroup.module.scss';
import { GroupsDropdown } from './GroupsDropdown';
import { SubgroupsDropdown } from './SubgroupsDropdown';

import { Alert, GitlabIcon, Info } from 'assets';
import { DialogContentWrapper } from 'components/JitDialogs/DialogContentWrapper/DialogContentWrapper';
import { JitExternalLink } from 'components/JitExternalLink/JitExternalLink';
import { JitInfoBanner } from 'components/JitInfoBanner/JitInfoBanner';
import { JitText } from 'components/JitText/JitText';
import { i18n } from 'locale/i18n';
import { IGroup, useGitlabService } from 'services/GitlabService/useGitlabService';
import colors from 'themes/colors.module.scss';
import { GitlabAccessLevel } from 'types/interfaces';
import { useSnackBar } from 'utils/hooks/useSnackBar';

interface Props {
  onContinueButtonClick: () => void;
  selectedRole: IntegrationRole;
  setSelectedGroup: (group: IGroup) => void;
  selectedGroup?: IGroup;
  setSelectedSubgroup: (group: IGroup) => void;
  selectedSubgroup?: IGroup;
  isLoading: boolean;
  handleClose: () => void;
}

export interface ValidationError {
  status: number;
  action: string;
}

export const ChooseGroup: FC<Props> = ({
  onContinueButtonClick, selectedRole, setSelectedGroup, selectedGroup, isLoading,
  setSelectedSubgroup, selectedSubgroup, handleClose,
}) => {
  const accessLevel = selectedRole === 'owner' ? GitlabAccessLevel.OWNER : GitlabAccessLevel.MAINTAINER;
  const [validationError, setValidationError] = useState<ValidationError | undefined>(undefined);
  const { showSnackBar } = useSnackBar();
  const { offboardIntegration } = useGitlabService();
  const [isOffboardLoading, setIsOffboardLoading] = useState(false);

  const onResetButtonClick = useCallback(async () => {
    setIsOffboardLoading(true);
    const response = await offboardIntegration();
    setIsOffboardLoading(false);
    if (response?.status !== 200) {
      showSnackBar({
        title: 'toasts.failedApiRequest.title',
        description: 'toasts.failedApiRequest.subtitle',
        type: 'error',
      });
    } else {
      handleClose();
    }
  }, [offboardIntegration, handleClose, showSnackBar]);

  const getActionButton = useCallback(() => {
    if (validationError) {
      return {
        label: 'GitlabIntegrationWizard.chooseGitlabGroup.error.buttonText',
        handleClick: onResetButtonClick,
        isLoading: isOffboardLoading,
      };
    }
    return {
      label: 'GitlabIntegrationWizard.chooseGitlabGroup.buttonText',
      disabled: !selectedGroup,
      handleClick: onContinueButtonClick,
      isLoading,
    };
  }, [isLoading, isOffboardLoading, onContinueButtonClick, onResetButtonClick, selectedGroup, validationError]);

  return (
    <DialogContentWrapper
      actionButtons={[getActionButton()]}
      icon={GitlabIcon}
      leftButtonsElement={(
        <JitExternalLink
          href={i18n.t(`GitlabIntegrationWizard.chooseGitlabGroup.docsReference.link.${selectedRole}`)}
          text='GitlabIntegrationWizard.chooseGitlabGroup.docsReference.text'
        />
          )}
    >
      <div className={styles.content}>
        <div className={styles.dropdowns}>
          <GroupsDropdown
            minAccessLevel={accessLevel}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setValidationError={setValidationError}
          />

          <SubgroupsDropdown
            groupId={selectedGroup?.id}
            minAccessLevel={accessLevel}
            selectedSubgroup={selectedSubgroup}
            setSelectedSubgroup={setSelectedSubgroup}
          />
        </div>

        <JitInfoBanner
          bgColor={validationError ? colors.failRedBackground02 : colors.cardContent}
          data-testid={validationError ? 'error-banner' : 'info-banner'}
          icon={validationError ? Alert : Info}
          iconColor={validationError ? colors.error : colors.blue}
        >
          <JitText
            data-testid='banner-text'
            size='s'
            text={validationError
              ? String(
                i18n.t('GitlabIntegrationWizard.chooseGitlabGroup.error.text', {
                  action: validationError.action,
                  reason: i18n.t(`GitlabIntegrationWizard.chooseGitlabGroup.error.${validationError.status}`, {
                    defaultValue: '',
                  }),
                }),
              )
              : 'GitlabIntegrationWizard.chooseGitlabGroup.info'}
          />
        </JitInfoBanner>

      </div>
    </DialogContentWrapper>
  );
};
