import { useMemo, useState } from 'react';

import { IntegrationRole } from '../StepComponents/ChooseIntegrationMethod/ChooseIntegrationMethod';

import { useChooseGroupStep } from './useChooseGroupStep';
import { useChooseIntegrationMethodStep } from './useChooseIntegrationMethodStep';
import { useConnectWebhooksStep } from './useConnectWebhooksStep';
import { useCreateMaintainerTokenStep } from './useCreateTokenStep';
import { useRunnerTypeSelectionStep } from './useRunnerTypeSelectionStep';
import { useVerifyPermissionsStep } from './useVerifyPermissionsStep';

import { IGroup } from 'services/GitlabService/useGitlabService';

interface Props {
  incrementStepIndex: () => void;
  handleClose: () => void;
}

export const useWizardSteps = ({ incrementStepIndex, handleClose }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState<IGroup>();
  const [selectedRole, setSelectedRole] = useState<IntegrationRole>('owner');

  const verifyPermissionsStep = useVerifyPermissionsStep({
    incrementStepIndex,
    handleClose,
  });

  const chooseIntegrationMethodStep = useChooseIntegrationMethodStep({
    incrementStepIndex,
    selectedRole,
    setSelectedRole,
  });

  const runnerTypeSelectionStep = useRunnerTypeSelectionStep({ handleClose });

  const createTokenStep = useCreateMaintainerTokenStep({ incrementStepIndex });

  const chooseGroupStep = useChooseGroupStep({
    incrementStepIndex,
    selectedGroup,
    setSelectedGroup,
    selectedRole,
    handleClose,
  });

  const connectWebhooksStep = useConnectWebhooksStep({ incrementStepIndex });

  const steps = useMemo(() => {
    const sharedSteps = [verifyPermissionsStep, chooseIntegrationMethodStep];

    if (selectedRole === 'owner') {
      return [...sharedSteps, chooseGroupStep, runnerTypeSelectionStep];
    }

    return [...sharedSteps, createTokenStep, chooseGroupStep, connectWebhooksStep, runnerTypeSelectionStep];
  }, [
    chooseGroupStep,
    chooseIntegrationMethodStep,
    connectWebhooksStep,
    createTokenStep,
    selectedRole,
    verifyPermissionsStep,
    runnerTypeSelectionStep,
  ]);

  return steps;
};
