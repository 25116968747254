import { useCallback } from 'react';

import { RunnerType } from 'components/GitlabIntegrationWizard/StepComponents/RunnerTypeSelection/RunnerTypeSelection';
import { getApiUrls } from 'services/apiUrls';
import { logError } from 'services/logger/logger';
import { useClient } from 'services/useClient';
import { IInstallation } from 'types/interfaces';
import { IPaginatedResponse } from 'types/interfaces/IPaginatedResponse/IPaginatedResponse';
import { FetchMembersParams } from 'types/interfaces/SCM/SCMMember';
import { parseObjectToQueryParams } from 'utils';
import { camelizeSnakeCaseKeys } from 'utils/functions/camelCaseConverter';

const WAIT_BETWEEN_RETRIES = 3000;
const MAX_RETRIES = 3;

export interface IGroup {
  id: string;
  name: string;
  path: string;
  fullPath: string;
}

export interface ICreateInstallation {
  group_id: string;
  group_name: string;
  group_slug: string;
  access_level: number;
}

export interface IProject {
  id: string;
  name: string;
  pathWithNamespace: string;
}
interface FetchProjectsParams {
  search?: string;
  page: number;
  per_page: number;
}

export const allProjectCoverageType = 'all' as const;
export const selectedProjectCoverageType = 'selected' as const;

export interface IUpdateInstallationProjects {
  project_coverage_type: typeof allProjectCoverageType | typeof selectedProjectCoverageType;
  projects?: {
    project_id: string;
    project_name: string;
  }[];
}

const wait = (ms: number): Promise<void> => new Promise((resolve) => {
  setTimeout(resolve, ms);
});

export interface FetchGroupsParams {
  page: number;
  per_page: number;
  search?: string;
  min_access_level?: number;
}

export interface IListProjectRunnersParams {
  runner_type?: RunnerType;
  online_only?: boolean;
}

export enum GitlabRunnerType {
  InstanceType = 'instance_type',
  GroupType = 'group_type',
  ProjectType = 'project_type',
}

export enum GitlabRunnerStatus {
  Online = 'online',
  Offline = 'offline',
  Paused = 'paused',
  Stale = 'stale',
  NeverConnected = 'never_connected',
  Active = 'active', // should be deprecated
}

export interface IGitlabRunner {
  active: boolean;
  paused: boolean;
  description: string;
  id: number;
  ip_address?: string;
  name?: string;
  online?: boolean;
  status: GitlabRunnerStatus | string; // | string is to support gitlab breaking changes
  is_shared: boolean;
  runner_type: GitlabRunnerType | string; // | string is to support gitlab breaking changes
}

export interface ICreateCentralizedProject {
  group_id: string;
}

export const useGitlabService = () => {
  const { client } = useClient();

  const fetchGroups = useCallback(async (params: FetchGroupsParams) => {
    const url = getApiUrls.gitlabService.getGroups();
    let retryCount = 0;

    while (retryCount <= WAIT_BETWEEN_RETRIES) {
      // eslint-disable-next-line no-await-in-loop
      const response = await client.get<{ groups: IGroup[] }>({
        url,
        allowedStatuses: [200, 425],
        requestConfig: {
          params,
        },
      });

      if (response?.status === 425) {
        if (retryCount === MAX_RETRIES) {
          logError(`Failed to fetch groups from Gitlab after ${MAX_RETRIES} retries`);
          return undefined;
        }
        // eslint-disable-next-line no-await-in-loop
        await wait(WAIT_BETWEEN_RETRIES);
        retryCount += 1;
      } else {
        return response?.data.groups;
      }
    }

    logError(`Failed to fetch groups from Gitlab after ${MAX_RETRIES} retries`);
    return undefined;
  }, [client]);

  const createInstallation = useCallback(async (body: ICreateInstallation) => {
    const url = getApiUrls.gitlabService.createInstallation();
    return client.post<IInstallation>({
      url,
      allowedStatuses: [201, 409],
      requestConfig: {
        data: body,
      },
    });
  }, [client]);

  const fetchProjects = useCallback(async (groupId: string, params: FetchProjectsParams) => {
    const url = getApiUrls.gitlabService.getProjects(groupId);
    const response = await client.get<{ projects: IProject[] }>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        params,
      },
    });

    return camelizeSnakeCaseKeys(response?.data.projects || []) as IProject[];
  }, [client]);

  const updateInstallationProjects = useCallback(async (installationId: string, body: IUpdateInstallationProjects) => {
    const url = getApiUrls.gitlabService.updateInstallationProjects(installationId);
    return client.post<IInstallation>({
      url,
      allowedStatuses: [200],
      requestConfig: {
        data: body,
      },
    });
  }, [client]);

  const fetchSubgroups = useCallback(async (groupId: string, params: FetchGroupsParams) => {
    const url = getApiUrls.gitlabService.getSubgroups(groupId);
    let retryCount = 0;

    while (retryCount <= WAIT_BETWEEN_RETRIES) {
      // eslint-disable-next-line no-await-in-loop
      const response = await client.get<{ groups: IGroup[] }>({
        url,
        allowedStatuses: [200, 425],
        requestConfig: {
          params,
        },
      });

      if (response?.status === 425) {
        if (retryCount === MAX_RETRIES) {
          logError(`Failed to fetch subgroups from Gitlab after ${MAX_RETRIES} retries`);
          return undefined;
        }
        // eslint-disable-next-line no-await-in-loop
        await wait(WAIT_BETWEEN_RETRIES);
        retryCount += 1;
      } else {
        return camelizeSnakeCaseKeys(response?.data.groups || []) as IGroup[];
      }
    }

    logError(`Failed to fetch subgroups from Gitlab after ${MAX_RETRIES} retries`);
    return undefined;
  }, [client]);

  const completeOnboarding = useCallback(async () => {
    const url = getApiUrls.gitlabService.completeOnBoarding();
    return client.post({
      url,
      allowedStatuses: [200],
    });
  }, [client]);

  const listProjectRunners = useCallback(async (projectId: string, params: IListProjectRunnersParams) => {
    const url = getApiUrls.gitlabService.listProjectRunners(projectId);

    return client.get<{ project_runners: IGitlabRunner[] }>({
      url,
      allowedStatuses: [200, 404],
      requestConfig: {
        params,
      },
    });
  }, [client]);

  const createCentralizedProject = useCallback(
    async (params?: ICreateCentralizedProject) => {
      const queryParams = params ? parseObjectToQueryParams(params) : undefined;
      const url = `${getApiUrls.gitlabService.createCentralizedProject()}?${queryParams}`;
      return client.post({
        url,
        allowedStatuses: [200],
      });
    },
    [client],
  );

  const getMembers = useCallback(async (params: FetchMembersParams) => {
    const url = getApiUrls.gitlabService.getMembers();
    const response = await client.get<IPaginatedResponse<string>>({
      url,
      allowedStatuses: [200],
      requestConfig: { params },
    });

    if (response?.status === 200) {
      return response.data;
    }
    throw new Error('Error fetching members');
  }, [client]);

  return {
    fetchGroups,
    createInstallation,
    fetchProjects,
    updateInstallationProjects,
    completeOnboarding,
    fetchSubgroups,
    listProjectRunners,
    createCentralizedProject,
    getMembers,
  };
};
